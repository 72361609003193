import React, { useEffect, useState } from "react";
import "./App.css";

import shieldClickService from "./services/shield_click/index";

function App() {
  const [blocked, setBlocked] = useState<boolean>(false);

  useEffect(() => {
    shieldClickService.getDecision().then(handleGetDecision).catch(handleError);
  }, []);

  const handleGetDecision = (response: any) => {
    if (response.re_fp) {
      localStorage.removeItem('sld_fped');
    }

    if (response.forward_to) {
      window.location.replace(response.forward_to);
    } else {
      setBlocked(true);
    }
  };

  const handleError = (err: any) => {
    console.log("Error: ", err);
  };

  return (
    <div className="container">
      <div className="dot-container">
        <div className="dot dot1" />
        <div className="dot dot2" />
        <div className="dot dot3" />
      </div>
      <p className="main-text"><b>Browser check in progress..</b></p>
      <p className="sub-text">You will be redirected to your content in a few seconds.</p>
      {blocked ? (
          <p className="sub-text blocked">Blocked</p>
      ) : null}
    </div>
  );
}

export default App;
