import { Shield } from "shield-js-fp";

const shield = new Shield();

const getDecision = (): Promise<unknown> => {
    const fingerprinted = localStorage.getItem('sld_fped');

    return shield
        .init('', '')
        .then((s: any) => {
            if (fingerprinted) {
                return shield.getDecision(true);
            } else {
                localStorage.setItem('sld_fped', 'true');
                return shield.getDecision(false);
            }
        })
}

const clickService = {
    getDecision
};

export default clickService;
